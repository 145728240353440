import styled from 'styled-components';
import TextArea from 'devextreme-react/text-area';
import { palette } from '@liasincontrol/ui-basics';

const StyledTextArea = styled(TextArea)<{fullHeight?: boolean}>`
    background-color: ${palette.white} !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;

    &.dx-textarea {
        height: ${props => props.fullHeight ? '100%' : 'auto'};
    }
`;

const styledComponents = { StyledTextArea };

export default styledComponents;
